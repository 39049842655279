import { required, email } from 'vuelidate/lib/validators'
export default {
  components: {
    ButtonBlock: () => import('~/components/commom/button-block/index'),
    InputBlock: () => import('~/components/commom/input-block/index'),
    ErrorBlock: () => import('~/components/commom/error-block/index')
  },
  data() {
    return {
      email: '',
      isLoading: false,
      serverError: false,
      serverErrorMessage: ''
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {
    currentLocale() {
      return this.$store.state.i18n.locale
    }
  },
  methods: {
    lowercaseEmail() {
      this.email = this.email.toLowercase()
    },
    addToMailingList() {
      this.$v.$touch()

      if (this.$v.$invalid || this.serverError) {
        return
      }

      this.isLoading = true
      this.$axios
        .post('/mautic/newsletter', {
          email
        })
        .then((_response) => {
          this.isLoading = false
        })
    }
  }
}
